import * as React from "react";
import { MobxComponent } from "../../../mobx/components/index";
import { Switch, TopNav } from "@lib/components";
import { LayoutDashboardComponents } from "./layout.c";
import { inject, observer } from "mobx-react";
import { Tooltip } from "@lib/components";
import { FaLifeRing, FaPowerOff, FaRocket } from "react-icons/fa";
import { withTranslation, WithTranslation } from "react-i18next";
import { IconSettings, IconClose, IconBack, IconStore, IconLandingPage, IconStaff, IconDevelopers, IconDocument, IconRoadmap, IconLogout, IconDashboard, IconCustomers, IconHamburger, IconWebsite } from "../../components/v2/icons";
import { SidebarLogoWrapper, HeaderTitleWrapper, MenuItem, QuickSettingsContainer, QuickSettingsHeaderWrapper, QuickSettingsBodyWrapper, QuickSettingsFooterWrapper, QuickSettingsButton, QuickSettingsItemsContainer, QuickSettingsItemsWrapper, QuickSettingsItemWrapper, HeaderMobileMenuContainer, HeaderMobileMenuUpperWrapper, HeaderMobileMenuTitleWrapper, HeaderMobileTitle, HeaderMobileMenuWrapper, HeaderMobile, HeaderMobileTitleWrapper } from "../../components/v2/layout.c";
import { QuickSettings } from "../../components/v2/quicksettings";

interface Props extends WithTranslation {
	children: React.ReactNode;
}
interface State {
	quickSettings: boolean;
	isMobile: boolean;
	activeMobileMenu: boolean;
}

@inject("store") @observer
class LayoutDashboardClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			quickSettings: false,
			isMobile: false,
			activeMobileMenu: false
		};
	}

	componentDidMount() {
		const script = document.createElement("script");
		script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAc_6JJnBdHbKjXGeoKIznTE22-YaRRuSA&libraries=geometry,places";
		document.body.appendChild(script);
		var _self = this;
		this.setState({
			isMobile: window.innerWidth < 768
		});

		window.addEventListener('resize', function() {
			if(window.innerWidth < 768) {
				_self.setState({
					isMobile: true
				})
			} else {
				_self.setState({
					isMobile: false
				})
			}
		})
	}

	toggleQuickSettings() {
		this.setState({
			quickSettings: !this.state.quickSettings
		})
	}

	toggleMobileMenu() {
        this.setState({
            activeMobileMenu: !this.state.activeMobileMenu,
        })
    }

	getPathnameText(pathname: string){
		if(pathname === '/website') return 'Landing Page'
		if(pathname === '/staff') return 'Staff'
		if(pathname === '/developer') return 'Developers'
		if(pathname === '/organization') return 'Organization'
		
		return 'Restaurants'
	}


	
	render() {
		const { store, t } = this.injected;
		const reseller = store.reseller!;
		const pathname = store.router.s.path;
		const { trialExpiry, trialExpired, restrictions, isStaff, isMainReseller } = store;
		const { children } = this.props;
		const { logo_icon } = reseller.admin;
		const links = [];
		const { isMobile } = this.state;

		if (
			restrictions.restaurant.create ||
			restrictions.restaurant.delete ||
			restrictions.restaurant.billing ||
			restrictions._.restaurantView
		) {
			links.push({
				text: "Restaurants",
				onClick: () => store.router.push("/"),
				active: pathname === "/",
				icon_mobile_only: <IconStore />
			});
		}

		if (restrictions.website.create || restrictions.website.edit) {
			links.push({
				text: "Landing Page",
				onClick: () => store.router.push("/website"),
				active: pathname === "/website",
				icon_mobile_only: <IconLandingPage />
			});
		}

		if (restrictions.staff.create || restrictions.staff.edit || restrictions.staff.delete) {
			links.push({
				text: "Staff",
				onClick: () => store.router.push("/staff"),
				active: pathname === "/staff",
				icon_mobile_only: <IconStaff />
			});
		}

		if (restrictions.api && (restrictions.api.create || restrictions.api.edit || restrictions.api.delete)) {
			links.push({
				text: "Developers",
				onClick: () => store.router.push("/developer"),
				active: pathname === "/developer",
				icon_mobile_only: <IconDevelopers />
			});
		}

    if (restrictions.api && (restrictions.api.create || restrictions.api.edit || restrictions.api.delete)) {
      links.push({
        text: "Organization",
        onClick: () => store.router.push("/organization"),
        active: pathname === "/organization",
		icon_mobile_only: <IconCustomers />
      });
    }

		return (
			<div>
				{ isMobile ?
				<>
					<HeaderMobile>
						<MenuItem
							onClick={() => this.toggleMobileMenu()}>
							<IconHamburger />
						</MenuItem> 
						<HeaderMobileTitleWrapper style={{justifyContent: 'center', flex: 1}}>
							<HeaderMobileTitle>
								{this.getPathnameText(pathname)}
							</HeaderMobileTitle>
						</HeaderMobileTitleWrapper>
					</HeaderMobile>
					<HeaderMobileMenuContainer  className={`${this.state.activeMobileMenu ? 'active' : ''}`}>
						<HeaderMobileMenuUpperWrapper>
							<HeaderMobileMenuTitleWrapper style={{borderBottom: '1px solid #dfdfdf'}}>
								<MenuItem
									onClick={() => this.toggleMobileMenu()}>
										<IconBack />
								</MenuItem>
								<HeaderMobileTitle>
									Menu
								</HeaderMobileTitle>
								<div></div>
							</HeaderMobileMenuTitleWrapper>
							<HeaderMobileMenuWrapper  style={{borderBottom: '1px solid #dfdfdf'}}>
								{links.map(link => (
								<MenuItem
									active = { link.active }
									onClick={() => {
										this.toggleMobileMenu()
										link.onClick()
									}}>
									{link.icon_mobile_only} {link.text}
								</MenuItem>
								))}
							</HeaderMobileMenuWrapper>
							<HeaderMobileMenuWrapper>
								<MenuItem
									onClick={() => { 
										this.toggleMobileMenu()
										store.updateView({quicksettings_active: true})
									}}>
									<IconSettings /> Settings
								</MenuItem>
								{isMainReseller && ( <>
									<MenuItem
										onClick={() => window.open('https://support.cloudwaitress.com', '_blank')}>
										<IconDocument /> Documentation
									</MenuItem>
									<MenuItem
										onClick={() => window.open('https://feedback.cloudwaitress.com/roadmap', '_blank')}>
										<IconRoadmap /> Roadmap and Feature Requests
									</MenuItem>
								</>
								)
								}
								<MenuItem onClick={() => store.service.logout()}>
								<IconLogout/>
									Log out
								</MenuItem>
							</HeaderMobileMenuWrapper>
						</HeaderMobileMenuUpperWrapper>
					</HeaderMobileMenuContainer>
				</>

				: <TopNav.Wrapper>
					<TopNav.Content width="lg" align="space-between">

						<div className="flex-line centered">

							<TopNav.Logo className="m-r-4">
								<img src={logo_icon} />
							</TopNav.Logo>

							<TopNav.Menu
								align="left"
								items={links}
							/>

						</div>

						<div className="flex-line centered child-mr-7">
						
							<Tooltip
								text="Settings"
								width={120}
								position="bottom"
								offset={5}>
								<TopNav.Icon onClick={() => store.updateView({quicksettings_active: true})}>
									<IconSettings />
								</TopNav.Icon>
							</Tooltip>
							
							{(!trialExpired && !isStaff) && (
								<Tooltip
									width={200}
									position="bottom"
									offset={10}
									text={<p className="lhp p-1">Ensure all your restaurants have a payment method to prevent issues</p>}>
									<p className="">
										Trial ends {t("dateFromTimestamp", { value: trialExpiry })}
									</p>
								</Tooltip>
							)}
							{isMainReseller && (
								<Tooltip
									text="Documentation"
									width={120}
									position="bottom"
									offset={5}>
									<a href="https://support.cloudwaitress.com" target="_blank">
										<TopNav.Icon>
											<FaLifeRing />
										</TopNav.Icon>
									</a>
								</Tooltip>
							)}
							{isMainReseller && (
								<Tooltip
									text={<span className="inline-block lhp p-lr-1">Roadmap & Feature Requests</span>}
									width={120}
									position="bottom"
									offset={5}>
									<a href="https://feedback.cloudwaitress.com/roadmap" target="_blank">
										<TopNav.Icon>
											<FaRocket />
										</TopNav.Icon>
									</a>
								</Tooltip>
							)}
							<Tooltip text="Logout" width={60} position="bottom" offset={5}>
								<TopNav.Icon onClick={() => store.service.logout()}>
									<FaPowerOff />
								</TopNav.Icon>
							</Tooltip>
						</div>

					</TopNav.Content>
				</TopNav.Wrapper> }

				<LayoutDashboardComponents.Content>
					{children}
				</LayoutDashboardComponents.Content>
				{store.view.quicksettings_active  && <QuickSettings />  }
			</div>
		);
	}

}

export const LayoutDashboard = withTranslation()(LayoutDashboardClass);
